import Registro from './components/Registro';


function App() {
  return (
     <div className="App">
      <header className="App-header">
        <Registro></Registro>
      </header>
    </div> 

  );
}

export default App;

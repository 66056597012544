import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "../css/promo.css"
import "../css/theme.css"
import "../css/theme.min.css"
import API from '@aws-amplify/api';

const Registro = (props) => {
  const [data, setData] = useState();
  const [statusButton, setStatusButton] = useState(false);
  const [formResponse, setFormResponse] = useState(null);
  const { register, formState: { errors }, watch, handleSubmit, resetField } = useForm({});

  const registerHandler = async (data) => {

    setStatusButton(true)
    const archivoConvertido = await convertirArchivoBase64(data.facturaFile[0])

    const datos = {
      telefono: data.Telefono,
      nif: data.nif,
      tienda: data.tienda,
      cedula: data.cedula,
      nombre: data.nombre,
      nombreFactura: data.facturaFile[0].name,
      factura: archivoConvertido,
      email: data.email,
      condiciones: data.condiciones
    }

    API.post('ganalacopawhirpool', '/ganalacopa',
      {
        body: datos
      }).then(result => {
        setStatusButton(false)
        setFormResponse(result?.message)
        if (result?.statusCode === 200) limpiarFormulario()
        else setFormResponse(result?.message??"Error inesperado al registrar los datos!")
        /*setData((prevData) => {
          return [result, prevData];
        })*/
      }).catch(error => {
          setStatusButton(false)
          setFormResponse("Error inesperado al registrar los datos!")
        }
      );
  }

  const limpiarFormulario = () => {
    resetField("nombre")
    resetField("Telefono")
    resetField("email")
    resetField("cedula")
    resetField("tienda")
    resetField("facturaFile")
    resetField("nif")
    resetField("condiciones")  
  }

  const convertirArchivoBase64 = (archivo) => {
    return new Promise(async (resolve, reject) => {
      var reader = new FileReader()
      reader.readAsDataURL(archivo)
      reader.onload = function () {
        var base64 = reader.result
        resolve(base64)
      }
    })
  }

  const validarArchivo = (file) => {

    const typeFile = (file[0].type).split('/')
    return typeFile[0] == 'image'

  }

  return (
    <>

      <form className="promo" onSubmit={handleSubmit(registerHandler)}>
        <div className="mb-3">
          <label htmlFor="Nombre" className="form-label promo">Nombre completo</label>
          <input type="text" className="form-control" id="Nombre" aria-describedby="NombreHelp"
            {...register('nombre', {
              required: true,
              // maxLength: 10
            })} />
          {errors.nombre?.type === 'required' && <p>El campo nombre es requerido</p>}
          {/* {errors.nombre?.type === 'maxLength' && <p>El campo nombre debe tener menos de 10 caracteres</p>} */}
        </div>
        <div className="mb-3">
          <label htmlFor="Telefono" className="form-label promo">Número telefónico</label>
          <input type="text" className="form-control" id="Telefono" aria-describedby="TelefonoHelp" {...register('Telefono', {
            required: true
          })} />
          {errors.Telefono?.type === 'required' && <p>El campo telefono es requerido</p>}
        </div>
        <div className="mb-3">
          <label htmlFor="Correo" className="form-label promo">Correo electrónico</label>
          <input type="email" className="form-control" id="Correo" aria-describedby="emailHelp"
            {...register('email', {
              required: true,
              pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i
            })}
          />
          {/* <p class="help is-danger">{errors.email && errors.email.message}</p> */}
          {errors.email?.type === 'required' && <p>El email es requerido</p>}
          {errors.email?.type === 'pattern' && <p>Formato de email invalido</p>}
        </div>
        <div className="mb-3">
          <label htmlFor="Cedula" className="form-label promo">Cédula</label>
          <input type="text" className="form-control" id="Cedula" {...register('cedula', {
            required: true
          })} />
          {errors.cedula?.type === 'required' && <p>La cédula es requerida</p>}
        </div>

        {/* <div className="mb-3">
          <label htmlFor="tienda" className="form-label promo" >Nombre de la tienda</label>
          <input type="text" className="form-control" id="tienda" {...register('tienda', {
            required: true
          })} />
          {errors.tienda?.type === 'required' && <p>La tienda es requerida</p>}
        </div> */}
        <div className="input-group mb-3">
          <label htmlFor="tienda" className="form-label promo">Nombre de la tienda</label>
          <select className="form-select" id="tienda" aria-label="selector para tiendas" {...register('tienda', {
            required: true
          })}> 
            <option value="Ferreteria Ochoa">Ferreteria Ochoa</option>
            <option value="L&R Comercial">L&R Comercial</option>
            <option value="Hi Fi Electrodomesticos">Hi Fi Electrodomesticos</option>
            <option value="InnovaCentro">InnovaCentro</option>
            <option value="Plaza Lama">Plaza Lama</option>
            <option value="CCN (Jumbo y Casa Cuesta)">CCN (Jumbo y Casa Cuesta)</option>
            <option value="Corripio">Corripio</option>
            <option value="Molina">Molina</option>
            <option value="Grupo Ramos (La Sirena)">Grupo Ramos (La Sirena)</option>
        </select>
          {errors.tienda?.type === 'required' && <p>La tienda es requerida</p>}
      </div>

        <div className="mb-3">
          <label htmlFor="facturaFile" className="form-label">Foto de la factura</label>
          <input className="form-control" type="file" id="facturaFile" {...register('facturaFile', {
            required: true,
            validate: validarArchivo
          })} />
          {errors.facturaFile?.type === 'required' && <p>La factura es requerida</p>}
          {errors.facturaFile && <p>La factura debe ser una imagen</p>}
        </div>

        <div className="mb-3">
          <label htmlFor="nif" className="form-label promo">Código NIF</label>
          <input type="text" className="form-control" id="nif" {...register('nif', {
            required: true
          })}
          />
          {errors.nif?.type === 'required' && <p>Código NIF es requerido</p>}
        </div>

        <div className="mb-3 form-check">
          <input type="checkbox" className="form-label promo" id="condiciones" {...register('condiciones', {
            required: true
          })} />
          <label className="form-check-label" htmlFor="condiciones">Acepto los <a términos href="terminos-y-condiciones.html"><strong className="form-check">términos y condiciones</strong></a></label>
          <br /><a href="https://www.whirlpool.pr/politicas-de-privacidad"><strong className="form-check">Política de privacidad </strong></a>
          {errors.condiciones?.type === 'required' && <p>Aceptar las condiciones es requerido</p>}
        </div>
        <button type="submit" className="btn btn-primary promo" disabled={statusButton}>Anotar!</button>

      </form>
      {(formResponse) ? <p><font color="white"> {formResponse}</font></p> : null}
    </>
  );
};

export default Registro;

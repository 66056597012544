/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "ganalacopawhirpool",
            "endpoint": "https://w0jlmsuz60.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_content_delivery_bucket": "ganalacopaui-20221021132840-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://ganalacopaui-20221021132840-hostingbucket-prod.s3-website-us-east-1.amazonaws.com"
};


export default awsmobile;
